// import React, {useState} from 'react';
// import { Yourproductscards } from '../../Components/Productcards';
// import { Yourproductsmobilecards } from '../../Components/Productcards';

// const Yourproducts = () => {
//   const [pageNumber, setPageNumber] = useState(false);
//   const [slicedProducts, setSlicedProducts] = useState(); // New state for sliced products
//   const [activeButtonIndex, setActiveButtonIndex] = useState('1');
//   const yourProductCard = JSON.parse(localStorage.getItem('yourproductscard'));
//   const active = {
//     backgroundColor: '#FEBD69',
//     border: "black 1px solid",
//     fontWeight: "bold",
//     borderRadius: "8px",
//     padding: "10px 15px",
//     color: "black",
//   };
//   const inactive = {
//     fontWeight: "bold",
//     color: "#646161",
//   };

//   if (!pageNumber){
//     setPageNumber(1);
//   }

//   const searchPage= async(e) => {
//     e.preventDefault();
//     const end = e.target.value * 15;
//     const start = end - 15;
//     await setActiveButtonIndex(null);
//     await setPageNumber(e.target.value);
//     const categories = await JSON.parse(localStorage.getItem('yourproductscard'));
//     if(start.length > categories.length) {
//      await setNoProducts(true);
//     }else{
//       await setSlicedProducts(categories.slice(start, end));
//     }
//   }
//   const handleCategories= async(index, num) => {
//     const end = num * 15;
//     const start = end - 15;
//     await setActiveButtonIndex(index);
//     await setPageNumber(num);
//     const categories = await JSON.parse(localStorage.getItem('yourproductscard'));
//     if(start.length > categories.length) {
//      await setNoProducts(true);
//     }else{
//       await setSlicedProducts(categories.slice(start, end));
//     }
//   }

//   return (
//     <div>
//       <div className='max-lg:hidden  w-[100%]'>
//       <div className='text-2xl text-bold my-4  '>
//         <h1>Yourproducts</h1>
//       </div>
//       <div className='flex flex-wrap justify-between items-center w-[100%]'>
//         <Yourproductscards  />
//         <div className='flex justify-end max-lg:hidden items-center gap-4 m-4'>
//         <div className='flex justify-center items-center gap-4 '>
//           <button onClick={() =>handleCategories(activeButtonIndex - 1, activeButtonIndex - 1)}><IoChevronBack className='categories-react-icons-arrow' /></button>
//           <button
//               onClick={() =>handleCategories('1', 1)}
//               style={activeButtonIndex === '1' ? active : inactive}
//             >
//               1
//             </button>
//           <button
//               onClick={() =>handleCategories('2', 2)}
//               style={activeButtonIndex === '2' ? active : inactive}
//             >
//               2
//             </button>
//           <button
//               onClick={() =>handleCategories('3', 3)}
//               style={activeButtonIndex === '3' ? active : inactive}
//             >
//               3
//             </button>
//           <button
//               onClick={() =>handleCategories('4', 4)}
//               style={activeButtonIndex === '4' ? active : inactive}
//             >
//               4
//             </button>
//           <button
//               onClick={() =>handleCategories('5', 5)}
//               style={activeButtonIndex === '5' ? active : inactive}
//             >
//               5
//             </button>
//           <button
//               onClick={() =>handleCategories('6', 6)}
//               style={activeButtonIndex === '6' ? active : inactive}
//             >
//               6
//             </button>
//           <button
//               onClick={() =>handleCategories('7', 7)}
//               style={activeButtonIndex === '7' ? active : inactive}
//             >
//               7
//             </button>
//           <button
//               onClick={() =>handleCategories('8', 8)}
//               style={activeButtonIndex === '8' ? active : inactive}
//             >
//               8
//             </button>
//           <button
//               onClick={() =>handleCategories('9', 9)}
//               style={activeButtonIndex === '9' ? active : inactive}
//             >
//               9
//             </button>
//           <button onClick={() =>handleCategories(activeButtonIndex + 1, activeButtonIndex + 1)}><IoChevronForwardOutline className='categories-react-icons-arrow' /></button>
//         </div>
//         <form onSubmit={searchPage} className='flex justify-center items-center gap-4 '>
//           <p className='text-[8px] text-[#FEBD69]'>Go to page</p>
//           <input className='p-2 rounded-md w-[12%] ' type='num' />
//           <button className='bg-white p-2 rounded-md '>Go</button>
//         </form>
//       </div>
//       <form onSubmit={searchPage} className='flex justify-between items-center gap-1  '>
//           <p className='text-[8px] text-[#FEBD69] w-[40%]'>Go to page</p>
//           <input className='px-2 py-1 rounded-md w-[30%] ' type='num' />
//           <button className='bg-white p-1 rounded-md '>Go</button>
//         </form>
//       </div>
//       </div>

//       <div className='hidden max-lg:block'>
//       <div className='text-2xl text-bold my-4  '>
//         <h1>Yourproducts</h1>
//       </div>
//       <div className='flex flex-wrap justify-between items-center w-[100%] '>
//         <Yourproductsmobilecards  />
//         <div className='hidden justify-between max-lg:flex items-center gap-1 my-2'>
//         <div className='flex justify-start items-center gap-2 text-[8px] '>
//           <button onClick={() =>handleCategories(activeButtonIndex - 1, activeButtonIndex - 1)}><IoChevronBack className='categories-react-icons-arrow' /></button>
//           <button
//               onClick={() => handleCategories('1', 1)}
//               style={activeButtonIndex === '1' ? active : inactive}
//             >
//               1
//             </button>
//           <button
//               onClick={() => handleCategories('2', 2)}
//               style={activeButtonIndex === '2' ? active : inactive}
//             >
//               2
//             </button>
//           <button
//               onClick={() => handleCategories('3', 3)}
//               style={activeButtonIndex === '3' ? active : inactive}
//             >
//               3
//             </button>
//           <button
//               onClick={() => handleCategories('4', 4)}
//               style={activeButtonIndex === '4' ? active : inactive}
//             >
//               4
//             </button>
//           <button
//               onClick={() => handleCategories('5', 5)}
//               style={activeButtonIndex === '5' ? active : inactive}
//             >
//               5
//             </button>
//           <button
//               onClick={() => handleCategories('6', 6)}
//               style={activeButtonIndex === '6' ? active : inactive}
//             >
//               6
//             </button>
//           <button
//               onClick={() => handleCategories('7', 7)}
//               style={activeButtonIndex === '7' ? active : inactive}
//             >
//               7
//             </button>
//           <button
//               onClick={() => handleCategories('8', 8)}
//               style={activeButtonIndex === '8' ? active : inactive}
//             >
//               8
//             </button>
//           <button
//               onClick={() => handleCategories('9', 9)}
//               style={activeButtonIndex === '9' ? active : inactive}
//             >
//               9
//             </button>
//           <button onClick={() =>handleCategories(activeButtonIndex + 1, activeButtonIndex + 1)}><IoChevronForwardOutline className='categories-react-icons-arrow' /></button>
//         </div>
//         <form onSubmit={searchPage} className='flex justify-between items-center gap-1  '>
//           <p className='text-[8px] text-[#FEBD69] w-[40%]'>Go to page</p>
//           <input className='px-2 py-1 rounded-md w-[30%] ' type='num' />
//           <button className='bg-white p-1 rounded-md '>Go</button>
//         </form>
        
//       </div>
//       </div>

//       </div>
//     </div>
//   );
// }

// export default Yourproducts;
import React, { useState, useEffect } from 'react';
import { Yourproductscards, Yourproductsmobilecards } from '../../Components/Productcards';
import { IoChevronBack, IoChevronForwardOutline } from 'react-icons/io5';

const Yourproducts = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [slicedProducts, setSlicedProducts] = useState([]);
  const [activeButtonIndex, setActiveButtonIndex] = useState('1');
  const [noProducts, setNoProducts] = useState(false);
  const yourProductCard = JSON.parse(localStorage.getItem('yourproductscard')) || [];

  const activeStyle = {
    backgroundColor: '#FEBD69',
    border: "1px solid black",
    fontWeight: "bold",
    borderRadius: "8px",
    padding: "10px 15px",
    color: "black",
  };
  const inactiveStyle = {
    fontWeight: "bold",
    color: "#646161",
  };

  useEffect(() => {
    handleCategories('1', 1);
  }, []);

  const searchPage = (e) => {
    e.preventDefault();
    const page = e.target.elements.pageInput.value;
    handleCategories(page, parseInt(page, 10));
  };

  const handleCategories = (index, num) => {
    const start = (num - 1) * 15;
    const end = start + 15;
    setActiveButtonIndex(index);
    setPageNumber(num);

    if (start >= yourProductCard.length) {
      setNoProducts(true);
      setSlicedProducts([]);
    } else {
      setNoProducts(false);
      setSlicedProducts(yourProductCard.slice(start, end));
    }
  };

  return (
    <div>
      <div className='max-lg:hidden w-full'>
        <div className='text-2xl font-bold my-4'>
          <h1>Your Products</h1>
        </div>
        <div className='flex flex-wrap justify-between items-center w-full'>
          <Yourproductscards products={slicedProducts} />
          <div className='flex justify-end items-center gap-4 m-4'>
            <button onClick={() => handleCategories(activeButtonIndex - 1, activeButtonIndex - 1)}>
              <IoChevronBack className='categories-react-icons-arrow' />
            </button>
            {[...Array(9).keys()].map(num => (
              <button
                key={num + 1}
                onClick={() => handleCategories((num + 1).toString(), num + 1)}
                style={activeButtonIndex === (num + 1).toString() ? activeStyle : inactiveStyle}
              >
                {num + 1}
              </button>
            ))}
            <button onClick={() => handleCategories(activeButtonIndex + 1, activeButtonIndex + 1)}>
              <IoChevronForwardOutline className='categories-react-icons-arrow' />
            </button>
          </div>
          <form onSubmit={searchPage} className='flex items-center gap-4'>
            <p className='text-xs text-[#FEBD69]'>Go to page</p>
            <input name="pageInput" className='p-2 rounded-md w-[12%]' type='number' min="1" max="9" />
            <button className='bg-white p-2 rounded-md'>Go</button>
          </form>
        </div>
      </div>

      <div className='hidden max-lg:block'>
        <div className='text-2xl font-bold my-4'>
          <h1>Your Products</h1>
        </div>
        <div className='flex flex-wrap justify-between items-center w-full'>
          <Yourproductsmobilecards products={slicedProducts} />
          <div className='flex justify-between items-center gap-2'>
            {[...Array(9).keys()].map(num => (
              <button
                key={num + 1}
                onClick={() => handleCategories((num + 1).toString(), num + 1)}
                style={activeButtonIndex === (num + 1).toString() ? activeStyle : inactiveStyle}
              >
                {num + 1}
              </button>
            ))}
            <form onSubmit={searchPage} className='flex items-center gap-1'>
              <p className='text-xs text-[#FEBD69]'>Go to page</p>
              <input name="pageInput" className='px-2 py-1 rounded-md w-[30%]' type='number' min="1" max="9" />
              <button className='bg-white p-1 rounded-md'>Go</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Yourproducts;
